import React from "react";

const PriceTable = () => {
  const pricingTiers = [
    {
      name: "Free",
      price: "$0/month",
      features: [
        "Browser-Based Editor",
        "Unlimited Projects",
        "Limited Access to AI Assistant (Coming Soon)",
      ],
    },
    {
      name: "Basic",
      price: "$9.99/month",
      prev_price: "$16.99",
      features: [
        "Access to OpenLane2 with 1 CPU and 16GB RAM",
        "Browser-Based Editor",
        "Unlimited Projects",
        "Unlimited Access to AI Assistant (Coming Soon)",
      ],
    },
    {
      name: "Pro (Coming Soon)",
      price: "$48/month",
      features: [
        "Access to OpenLane2 with 3 CPUs",
        "Browser-Based Editor",
        "Unlimited Projects",
        "Unlimited Storage",
        "Customizable AI Assistant",
      ],
    },
  ];
  return (
    <div id="pricing" className="text-center" style={{ marginBottom: "40px" }}>
      <div className="container" justify-content-center>
        <div className="section-title" style={{ marginBottom: "40px" }}>
          <h2>ChipWorks Plans</h2>
        </div>

        <div className="pricing-container">
          {pricingTiers.map((tier, index) => (
            <div key={index} className="pricing-card">
              <h3 className="tier-name">{tier.name}</h3>
              <div
                className="tier-price-container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {tier.prev_price && (
                  <p className="tier-prev-price strikediag">
                    {tier.prev_price}
                  </p>
                )}
                <p className="tier-price">{tier.price}</p>
              </div>
              <ul className="tier-features">
                {tier.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>{feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PriceTable;
