import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
        <p>Contact us: <a href="mailto:chipworks.us@gmail.com">chipworks.us@gmail.com</a></p>
        <p>&copy; 2024 Chipworks. All rights reserved.</p>
    </footer>
  );
};

export default Footer;