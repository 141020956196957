import React from "react";
import { Navigation } from "./navigation";
import Footer from "./footer";

export const Editor = (props) => {
  return (
    <div>
      <Navigation />
      <header id="editor-header">
        <div className="container">
          <div className="row two-columns">
            {" "}
            {/* Updated class name for styling */}
            <div className="column">
              <div className="intro-text">
                <h1>The ChipWorks Editor</h1>
                <p>
                  Our editor is a powerful tool that allows you to create your
                  own custom designs from the browser. Access your work from
                  anywhere and run the OpenLane2 flow to generate GDSII files.
                </p>
                <p>
                  Watch how easy it is to create a custom design and run the
                  flow using our editor in the video below.
                </p>
              </div>
            </div>
            <div className="column">
              <video loop autoPlay muted>
                <source src="/vid/randomvideo.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="column">
              <div className="intro-text">
                <p>
                  Coming soon: Our fine-tuned AI engineer, Chipper, will be
                  available to help you design your custom chip. Chipper can
                  help you with the design process by providing suggestions and
                  feedback in addition to writing full Verilog modules for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Footer />
    </div>
  );
};
