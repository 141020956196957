import React from "react";

export const Intro = () => {
  return (
    <div className="intro" >
      <div className="intro-text">
        <h1 className="intro-title">Silicon, <b><em>Simplified.</em></b></h1>
        <p className="intro-subtitle">Get to silicon <b>quicker</b> with our suite of web-based tools</p>
        <div className="intro-overlay">
          <a className="intro-button" href="https://application.chipworks.app"><span style={{fontWeight : "bold"}}>Make an Account</span> to Get Started Now</a>
          <img className="intro-image" src="/img/editor.png" alt="ChipWorks Editor" style={{ width: "80vw", borderRadius: "5px" }} />
        </div>
      </div>
    </div>
  );
}