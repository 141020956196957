import React from "react";
import { Link } from "react-router-dom";

export const EditorHeader = (props) => {
  return (
    <header id="header">
      <div className="container">
        <div className="row two-columns">
          {" "}
          {/* Updated class name for styling */}
          <div className="column">
            <div className="intro-text">
              <h1>Empower Your Design Vision</h1>
              <p>
                Bypass the complexity required by existing solutions. Eliminate
                the learning curve and streamline your design process, from
                ideation to deployment, with the power of cloud computing on our
                easy-to-use platform.
              </p>
              <p>
                <Link
                  to="/editor"
                  style={{ fontWeight: "bold", color: "#28282a" }}
                >
                  Learn more
                </Link>{" "}
                about our browser-based editor and how it can help you bring
                your design vision to life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
