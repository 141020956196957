import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./components/home.jsx";
import { Editor } from "./components/editor.jsx";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import ReactGA from "react-ga4";

ReactGA.initialize("G-RQP4Z9Z9JB");

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/editor" element={<Editor />} />
    </Routes>
  );
};

export default App;
