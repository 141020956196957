import React, { useState } from "react";

import ReactGA from "react-ga4";

export const Navigation = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    console.log("toggleDropdown");
    console.log(dropdownOpen);
    setDropdownOpen(!dropdownOpen);
  };

  const trackClick = (event) => {
    ReactGA.event({
      category: "Navigation",
      action: "click_sign_up",
    });
  };

  return (
    <nav className="navbar">
      <a className="home-button" href="/">
        <div
          className="logo"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Align items vertically
            height: "fit-content",
          }}
        >
          <img
            src="/img/ChipWorks.png"
            alt="logo"
            style={{
              height: "40px",
              width: "40px",
              padding: 0,
              borderRadius: 0,
            }}
          />
          <p
            style={{
              marginLeft: "15px",
              marginBottom: 0,
              fontSize: "24pt",
              color: "black",
            }}
          >
            ChipWorks
          </p>
        </div>
      </a>

      {/* Navigation Links */}
      {props.showLinks ? (
        <>
          <ul className="nav-links">
            {/* <li className="list-button" style={{ marginRight: "5px" }}>
              <a className="nav-link" href="/#ai-assistant">
                AI Engineer
              </a>
            </li> */}
            <li className="list-button" style={{ marginRight: "5px" }}>
              <a className="nav-link" href="/#Features">
                Editor
              </a>
            </li>
            <li className="list-button" style={{ marginRight: "5px" }}>
              <a className="nav-link" href="/#pricing">
                Plans
              </a>
            </li>
            <li className="list-button" style={{ marginRight: "5px" }}>
              <a className="nav-link" href="/#About">
                About
              </a>
            </li>
            <li
              className="focus-button"
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={trackClick}
            >
              <a
                className="standout-nav-link"
                href="https://application.chipworks.app"
              >
                Login or Signup
              </a>
            </li>
          </ul>

          <button onClick={toggleDropdown} className="dropdown-button">
            <i className="fa fa-bars"></i>
          </button>

          <ul
            className={`nav-dropdown-menu-right ${dropdownOpen ? "open" : ""}`}
          >
            {/* <li className="drop-list-button">
              <a className="drop-nav-link" href="#ai-assistant">
                AI Engineer
              </a>
            </li> */}
            <li className="drop-list-button">
              <a className="drop-nav-link" href="#Features">
                Editor
              </a>
            </li>
            <li className="drop-list-button">
              <a className="drop-nav-link" href="#pricing">
                Plans
              </a>
            </li>
            <li className="drop-list-button">
              <a className="drop-nav-link" href="#About">
                About
              </a>
            </li>
            <li className="drop-focus-button" onClick={trackClick}>
              <a
                className="drop-nav-link"
                href="https://application.chipworks.app"
              >
                Login or Signup
              </a>
            </li>
          </ul>
        </>
      ) : (
        <>
          <ul className="nav-links">
            <li
              className="focus-button"
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={trackClick}
            >
              <a
                className="standout-nav-link"
                href="https://application.chipworks.app"
              >
                Login or Signup
              </a>
            </li>
          </ul>

          <button onClick={toggleDropdown} className="dropdown-button">
            <i className="fa fa-bars"></i>
          </button>

          <ul
            className={`nav-dropdown-menu-right ${dropdownOpen ? "open" : ""}`}
          >
            <li className="drop-focus-button" onClick={trackClick}>
              <a
                className="drop-nav-link"
                href="https://application.chipworks.app"
              >
                Login or Signup
              </a>
            </li>
          </ul>
        </>
      )}
    </nav>
  );
};
