import React from "react";

export const About = (props) => {
  return (
    <div id="about">
        <h2>About Us</h2>
        <p>{props.data ? props.data.paragraph : "loading..."}</p>
    </div>
  );
};
