import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";

import { Intro } from "./intro";
// import { Chipper } from "./chipper";

import { EditorHeader } from "./EditorHeader";
import { Features } from "./features";
import { About } from "./about";

import JsonData from "../data/data.json";
import PriceTable from "./Pricetable";
import Footer from "./footer";
import "../App.css";

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation showLinks={true} />
      <Intro />
      {/* <Chipper /> */}
      <EditorHeader />
      <Features data={landingPageData.Features} />
      <PriceTable />
      <About data={landingPageData.About} />
      <Footer />
    </div>
  );
};
